var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-type',{attrs:{"visible":_vm.visible,"title":_vm.title,"description":_vm.description,"type":"confirmation","custom-request":_vm.handleChangeCompany,"ok-text":"Cambiar a esta subcuenta","ok-disabled":_vm.disabledOk},on:{"onClose":_vm.handleCloseModal}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleChangeCompany.apply(null, arguments)}}},[_c('p',[_vm._v(" Ingresa tu contraseña con la que iniciaste sesión para validar el cambio de subcuenta. ")]),(_vm.visible)?_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              { required: true, message: 'Este campo es requerido' },
              {
                whitespace: true,
                message: 'No se admiten espacios en blanco.',
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              { required: true, message: 'Este campo es requerido' },\n              {\n                whitespace: true,\n                message: 'No se admiten espacios en blanco.',\n              },\n            ],\n          },\n        ]"}],ref:"form_item_password",attrs:{"placeholder":"Escribe aquí..."},on:{"input":_vm.hasErrorInForm}}),_vm._v(" "+_vm._s(_vm.setFocusInPassword())+" ")],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }