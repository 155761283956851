var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[(_vm.alert.visible)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"message":_vm.alert.message,"type":_vm.alert.type,"show-icon":""}}):_vm._e(),_c('h5',{staticClass:"heading-h5"},[_vm._v("Restablece tu contraseña")]),_c('p',{staticClass:"body-2"},[_vm._v(" Escribe tu dirección de email y te enviaremos un enlace para restablecer tu contraseña. Recuerda que tu correo deberá estar afiliado a "+_vm._s(_vm.app)+". ")]),_c('a-form',{staticClass:"section__form",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Correo"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              { required: true, message: 'Este campo es requerido' },
              { type: 'email', message: 'No es un correo válido' },
              {
                whitespace: true,
                message: 'No se permiten espacios en blanco',
              } ],
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              { required: true, message: 'Este campo es requerido' },\n              { type: 'email', message: 'No es un correo válido' },\n              {\n                whitespace: true,\n                message: 'No se permiten espacios en blanco',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Escribe aquí...","size":_vm.inputs_size}})],1),_c('a-row',{attrs:{"gutter":{ xs: 8, sm: 8, md: 8, lg: 12, xl: 12 }}},[_c('a-col',{attrs:{"span":7}},[_c('a-button',{staticClass:"section__footer__button",attrs:{"size":_vm.buttons_size},on:{"click":_vm.handleBack}},[_vm._v("Volver")])],1),_c('a-col',{attrs:{"span":17}},[_c('a-button',{staticClass:"section__footer__button",attrs:{"type":"primary","html-type":"submit","disabled":_vm.disabled,"loading":_vm.loading,"size":_vm.buttons_size}},[_vm._v("Restablece tu contraseña")])],1)],1),(_vm.visibleMessage)?_c('p',{staticClass:"regular-14 mrg-top-12 form__message"},[_c('i',[_vm._v("Podrás volver a intentar en "+_vm._s(_vm.timerSeconds)+" segundos")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }