var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text--left"},[_vm._t("title",function(){return [_c('h4',{staticClass:"semibold-20 section__title"},[_vm._v(" Instalación del código de Webchat ")])]}),_c('p',{staticClass:"regular-14 mrg-bottom-8"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('p',{staticClass:"regular-14 mrg-bottom-20"},[_vm._v(" Para instalar el webchat a landings internas, se debe agregar el código de instalación en cada página. Más info "),_c('anchor',{attrs:{"href":((_vm.vari.WEB_DOC) + "webchat/integrar-webchat/"),"title":"aquí."}})],1),_c('a-textarea',{attrs:{"placeholder":"Escribe aquí...","auto-size":{ minRows: 5, maxRows: 5 },"readOnly":""},model:{value:(_vm.scriptIntegration),callback:function ($$v) {_vm.scriptIntegration=$$v},expression:"scriptIntegration"}}),_c('div',{staticClass:"display-flex align--end justify--between mrg-top-12 mrg-bottom-40"},[_c('p',{staticClass:"regular-14 mrg-bottom-0"},[_vm._v(" ¿Ayuda? Sigue los pasos "),_c('anchor',{attrs:{"href":((_vm.vari.WEB_DOC) + "webchat/configurar-webchat/"),"title":"aquí"}})],1),_c('a-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.scriptIntegration),expression:"scriptIntegration",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopyScript),expression:"onCopyScript",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"}],attrs:{"type":"primary"}},[_vm._v("Copiar código")])],1),_c('h5',{staticClass:"semibold-14"},[_vm._v("Envía el código de instalación")]),_c('p',{staticClass:"regular-14"},[_vm._v(" Enviaselo a tu desarrollador o al administrador de tu web ")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('div',{staticClass:"display-flex"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Por favor rellena el campo.',
                },
                {
                  type: 'email',
                  message: 'Ingresa un correo válido.',
                },
                {
                  whitespace: true,
                  message: 'No se aceptan espacios en blanco.',
                },
                {
                  min: 6,
                  message: '6 caracteres como mínimo.',
                },
                {
                  max: 50,
                  message: '50 caracteres como máximo.',
                } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Por favor rellena el campo.',\n                },\n                {\n                  type: 'email',\n                  message: 'Ingresa un correo válido.',\n                },\n                {\n                  whitespace: true,\n                  message: 'No se aceptan espacios en blanco.',\n                },\n                {\n                  min: 6,\n                  message: '6 caracteres como mínimo.',\n                },\n                {\n                  max: 50,\n                  message: '50 caracteres como máximo.',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Escribe aquí un correo electrónico","disabled":_vm.disabled},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('a-button',{staticClass:"mrg-left-12",attrs:{"type":"primary","ghost":"","disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.handleSendScript}},[_vm._v("Enviar")])],1)])],1),(_vm.visibleMessage)?_c('p',{staticClass:"regular-14 mrg-top-12 form__message"},[_c('i',[_vm._v("Podrás volver a intentar en "+_vm._s(_vm.timerSeconds)+" segundos")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }