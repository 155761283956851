import { render, staticRenderFns } from "./ListItemChannel.vue?vue&type=template&id=94a4a85c&scoped=true&"
import script from "./ListItemChannel.vue?vue&type=script&lang=js&"
export * from "./ListItemChannel.vue?vue&type=script&lang=js&"
import style0 from "./ListItemChannel.vue?vue&type=style&index=0&id=94a4a85c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94a4a85c",
  null
  
)

export default component.exports