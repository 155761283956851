var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"text--left",attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._t("title",function(){return [_c('h4',{staticClass:"semibold-20"},[_vm._v("Perfil de webchat")])]}),_c('p',{staticClass:"regular-14"},[_vm._v(" Configura el nombre, estiliza y crea la mejor experiencia para tu webchat. ")]),_c('a-form-item',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v("Nombre")]),_c('p',{staticClass:"regular-14 mrg-bottom-12 text--left"},[_vm._v(" Escribe un nombre que identifique a tu empresa. ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'title',
        {
          rules: [
            {
              required: true,
              message: 'Por favor rellena este campo',
            },
            {
              whitespace: true,
              message: 'No se admiten espacios en blanco.',
            } ],
        } ]),expression:"[\n        'title',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Por favor rellena este campo',\n            },\n            {\n              whitespace: true,\n              message: 'No se admiten espacios en blanco.',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Escribe aquí...","maxLength":30},on:{"input":function($event){return _vm.handleInputTitle({ title: $event.target.value })}}})],1),_c('div',[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v("Color")]),_c('p',{staticClass:"regular-14 mrg-bottom-12 text--left"},[_vm._v(" Elige un color que constraste con la marca de tu empresa ")]),_c('color-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['color']),expression:"['color']"}],attrs:{"default-color":_vm.defaultColor},on:{"onChange":_vm.handleChangeColor,"onError":function (err) { return (_vm.errorColor = err); }}})],1),_c('a-form-item',[_c('div',{staticClass:"display-flex"},[_c('uploader-avatar',{directives:[{name:"decorator",rawName:"v-decorator",value:(['avatarUrl']),expression:"['avatarUrl']"}],attrs:{"default-avatar":_vm.defaultAvatar},on:{"onChange":_vm.handleChangeAvatar}}),_c('div',{staticClass:"form-item__text text--left"},[_c('h6',{staticClass:"semibold-14 text--left mrg-bottom-4"},[_vm._v(" Sube aquí el avatar ")]),_c('p',{staticClass:"regular-14 mrg-bottom-0"},[_vm._v(" El tipo de imagen ideal es jpg o png ")]),_c('p',{staticClass:"regular-14 mrg-bottom-0"},[_vm._v(" Las dimensiones ideales son 500px x 500px ")])])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }