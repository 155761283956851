var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"title":_vm.title,"placement":_vm.placement,"visible":_vm.visible,"width":"410px","bodyStyle":_vm.bodyStyle},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('custom-section-scroll',{attrs:{"master-styles":{
        height: ("calc(100vh - (" + _vm.heightHeader + " + " + _vm.heightFooter + "))"),
      },"content-styles":{ padding: '24px' }}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Imagen de producto"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['picture']),expression:"['picture']"}],staticClass:"drawer__uploader drawer-product__uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":false,"before-upload":_vm.beforeUpload,"customRequest":_vm.selfUpload}},[(_vm.imageUrl)?_c('div',{staticClass:"drawer__content-picture"},[_c('div',{staticClass:"\n                    drawer__overlay\n                    align--center\n                    justify-content--center\n                    direction-column\n                  "},[_c('a-icon',{attrs:{"type":"edit"}}),_c('p',{staticClass:"regular-14"},[_vm._v("Cambiar imagen")])],1),_c('img',{staticClass:"drawer__picture",attrs:{"src":_vm.imageUrl,"alt":"imagen del producto"}})]):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("Subir imagen")])],1)])],1),_c('a-form-item',{attrs:{"label":"Nombre"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor rellena este campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    } ],
                } ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Por favor rellena este campo',\n                    },\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Casaca jeans, Sandalias negras...","maxLength":30}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Descripción"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Por favor rellena este campo',
                    },
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    } ],
                } ]),expression:"[\n                'description',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Por favor rellena este campo',\n                    },\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"auto-size":{ minRows: 3, maxRows: 5 },"maxLength":_vm.maxDescription,"placeholder":"Disponible en tienda física y tienda online..."},on:{"input":_vm.handleInputDescription}}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.currentDescription ? _vm.currentDescription.length : 0)+"/"+_vm._s(_vm.maxDescription))])],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Marca"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'brand',
                {
                  rules: [
                    {
                      whitespace: true,
                      message: 'No se admiten espacios en blanco.',
                    } ],
                } ]),expression:"[\n                'brand',\n                {\n                  rules: [\n                    {\n                      whitespace: true,\n                      message: 'No se admiten espacios en blanco.',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Adidas..."}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Tipo de Moneda"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'currency_code',
                {
                  initialValue: _vm.currency.currency_code,
                  rules: [
                    { required: true, message: 'Please select an owner' } ],
                } ]),expression:"[\n                'currency_code',\n                {\n                  initialValue: currency.currency_code,\n                  rules: [\n                    { required: true, message: 'Please select an owner' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Please a-s an owner","option-filter-prop":"children","show-search":""},on:{"change":_vm.handleChangeCurrency}},_vm._l((_vm.currencies),function(currency){return _c('a-select-option',{key:currency.code},[_vm._v(" "+_vm._s(currency.symbol)+" "+_vm._s(currency.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Precio"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'price',
                {
                  initialValue: 0,
                  rules: [
                    { required: true, message: 'Completa este campo' },
                    { validator: _vm.validateMaxDiscount } ],
                } ]),expression:"[\n                'price',\n                {\n                  initialValue: 0,\n                  rules: [\n                    { required: true, message: 'Completa este campo' },\n                    { validator: validateMaxDiscount },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":function (value) { return ((_vm.currency.currency_symbol) + " " + value); },"parser":_vm.handleParserNumber,"placeholder":"50"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Descuento máximo  "),_c('a-tooltip',{attrs:{"title":"El descuento no debe ser mayor al precio","placement":"topRight"}},[_c('a-icon',{attrs:{"type":"info-circle-o"}})],1)],1),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'maximum_discount',
                {
                  initialValue: 0,
                  rules: [
                    { required: true, message: 'Completa este campo' },
                    { validator: _vm.compareToPrice } ],
                } ]),expression:"[\n                'maximum_discount',\n                {\n                  initialValue: 0,\n                  rules: [\n                    { required: true, message: 'Completa este campo' },\n                    { validator: compareToPrice },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":function (value) { return ((_vm.currency.currency_symbol) + " " + value); },"parser":_vm.handleParserNumber,"placeholder":"50"}})],1)],1)],1),_c('a-row',[_c('h6',{staticClass:"semibold-14"},[_vm._v("Campos personalizados")]),(_vm.fields_company_products.length === 0)?[_c('p',{staticClass:"empty"},[_vm._v("No hay campos personalizados")])]:_vm._l((_vm.localCustomFields),function(localField,index){return _c('a-col',{key:localField.customFieldId,attrs:{"span":24}},[_c('custom-field-option',{attrs:{"field":localField,"hasRange":false,"dataSource":_vm.disabledFields(_vm.fields_company_products),"isLast":_vm.localCustomFields.length - 1 === index},on:{"onChangeField":_vm.updateCustomField,"onRemove":function($event){return _vm.onRemoveField(localField.customFieldId)}}})],1)}),(_vm.fields_company_products.length > 0)?[_c('a',{staticClass:"add",on:{"click":_vm.addLocalField}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Agregar campo customizado ")],1)]:_vm._e()],2)],1),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-button',{style:({ marginRight: '8px' }),attrs:{"disabled":_vm.disabledCancel},on:{"click":_vm.onClose}},[_vm._v(" Cancelar ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingOk,"disabled":_vm.disabledOk}},[_vm._v(" "+_vm._s(_vm.okText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }