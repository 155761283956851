var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"picker",attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"display-flex align--center justify-content--center range__content"},[_c('a-form-item',{staticClass:"picker__field",attrs:{"validate-status":_vm.validateStatusStart}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'start',
          {
            rules: [{ validator: _vm.hasValueStart }],
          } ]),expression:"[\n          'start',\n          {\n            rules: [{ validator: hasValueStart }],\n          },\n        ]"}],staticStyle:{"width":"10em"},attrs:{"placeholder":"Hora de inicio","format":"HH:mm","disabled-hours":function () { return _vm.filterDisabledHours(_vm.disabledHours); },"disabled-minutes":function () { return _vm.filterDisabledMinutes(_vm.disabledMinutes, 'start'); },"allowClear":false},on:{"openChange":_vm.handleStartOpenChange,"change":function($event){return _vm.handleChangePicker($event, 'start')}}})],1),_c('a-icon',{staticClass:"range__icon",attrs:{"type":"minus"}}),_c('a-form-item',{staticClass:"picker__field",attrs:{"validate-status":_vm.validateStatusEnd}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'end',
          {
            rules: [{ validator: _vm.hasValueEnd }],
          } ]),expression:"[\n          'end',\n          {\n            rules: [{ validator: hasValueEnd }],\n          },\n        ]"}],staticStyle:{"width":"10em"},attrs:{"placeholder":"Hora de fin","format":"HH:mm","disabled-hours":function () { return _vm.filterDisabledHours(_vm.disabledHours); },"disabled-minutes":function () { return _vm.filterDisabledMinutes(_vm.disabledMinutes, 'end'); },"allowClear":false},on:{"openChange":_vm.handleEndOpenChange,"change":function($event){return _vm.handleChangePicker($event, 'end')}}})],1),(_vm.showIconRemove)?_c('a-icon',{staticClass:"picker__icon--remove",attrs:{"type":"delete"},on:{"click":function($event){return _vm.$emit('onRemove', _vm.idPicker)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }