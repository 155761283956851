var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-company"},[_c('h5',{staticClass:"heading-h5"},[_vm._v("Mi empresa")]),(_vm.profile && _vm.company)?_c('a-form',{staticClass:"form",attrs:{"form":_vm.form,"hideRequiredMark":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"master-container",attrs:{"id":"menu-company-master-container"}},[_c('div',{staticClass:"container scrollbar_basic",attrs:{"id":"menu-company-container"},on:{"scroll":function($event){return _vm.setShadows(
            'menu-company-master-container',
            'menu-company-container',
            'menu-company-content'
          )}}},[_c('div',{staticClass:"content",attrs:{"id":"menu-company-content"}},[_c('p',{staticClass:"body-2 description"},[_vm._v(" Recuerda que el banner de tu empresa lo tendrán todos tus usuarios. ")]),_c('div',{staticClass:"upload-container"},[_c('a-upload',{staticClass:"upload",attrs:{"name":"avatar","multiple":false,"show-upload-list":false,"before-upload":_vm.beforeUpload,"customRequest":_vm.selfUpload}},[(_vm.imageUrl)?_c('img',{staticStyle:{"object-fit":"cover","border-radius":"4px"},attrs:{"src":_vm.imageUrl,"alt":"Banner de compañia","height":"96px","width":"246px"}}):_vm._e(),_c('div',{staticClass:"overlay",class:{ 'opacity-1': _vm.loading }},[(!_vm.loading)?_c('a-icon',{staticStyle:{"font-size":"20px","color":"white"},attrs:{"type":"edit"}}):_c('a-icon',{staticStyle:{"font-size":"20px","color":"white"},attrs:{"type":"loading"}})],1)]),_c('div',{staticClass:"text"},[_c('p',{staticClass:"heading-h8 subtitle",staticStyle:{"margin-bottom":"0"}},[_vm._v(" Subir aquí un nuevo banner ")]),_c('p',{staticClass:"body-2",staticStyle:{"margin-bottom":"0"}},[_vm._v(" El tamaño máximo permitido es de 2MB ")]),_c('p',{staticClass:"body-2",staticStyle:{"margin-bottom":"0"}},[_vm._v(" Las dimensiones ideales son 332px x 87px ")])])],1),_c('h6',{staticClass:"heading-h6"},[_vm._v("Datos de mi empresa")]),_c('div',{staticClass:"form-items"},[_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"Nombres","has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Este campo es requerido',
                      },
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      },
                      {
                        validator: _vm.validateNameCompany,
                      } ],
                  },
                  ,
                ]),expression:"[\n                  'name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Este campo es requerido',\n                      },\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                      {\n                        validator: validateNameCompany,\n                      },\n                    ],\n                  },\n                  ,\n                ]"}],attrs:{"placeholder":"Escribe aquì..."}})],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"País"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'country',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'country',\n                  {\n                    rules: [\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"show-search":"","placeholder":"Selecciona aquí"}},_vm._l((_vm.countriesNotRepeatByName),function(country){return _c('a-select-option',{key:country.nameSpanish},[_vm._v(" "+_vm._s(country.nameSpanish)+" ")])}),1)],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"Industria"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'industry',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'industry',\n                  {\n                    rules: [\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selecciona aquí"}},_vm._l((_vm.industries),function(industry){return _c('a-select-option',{key:industry},[_vm._v(" "+_vm._s(industry)+" ")])}),1)],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"Operación"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'operation',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'operation',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"mode":"multiple","placeholder":"Selecciona aquí"}},_vm._l((_vm.operations),function(operation){return _c('a-select-option',{key:operation.key},[_vm._v(" "+_vm._s(operation.value)+" ")])}),1)],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"N° de colaboradores"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'workers',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'workers',\n                  {\n                    rules: [\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Selecciona aquí"}},_vm._l((_vm.collaborators),function(collaborator){return _c('a-select-option',{key:collaborator},[_vm._v(" "+_vm._s(collaborator)+" ")])}),1)],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"Dirección"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'address',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'address',\n                  {\n                    rules: [\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Escribe aquì..."}})],1),_c('a-form-item',_vm._b({staticClass:"form-item",attrs:{"label":"Web page"}},'a-form-item',_vm.formItemLayout,false),[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'webpage',
                  {
                    rules: [
                      {
                        whitespace: true,
                        message: 'No se aceptan espacios vacios',
                      } ],
                  } ]),expression:"[\n                  'webpage',\n                  {\n                    rules: [\n                      {\n                        whitespace: true,\n                        message: 'No se aceptan espacios vacios',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Escribe aquí..."},on:{"change":_vm.handleWebsiteChange}},[_c('template',{slot:"dataSource"},_vm._l((_vm.autoCompleteResult),function(website){return _c('a-select-option',{key:website},[_vm._v(" "+_vm._s(website)+" ")])}),1),_c('a-input')],2)],1)],1)])])]),_c('div',{staticClass:"footer"},[_c('a-button',{attrs:{"disabled":_vm.hasNewValues(_vm.form.getFieldsValue()) && !_vm.newBanner},on:{"click":_vm.handleCancel}},[_vm._v("Cancelar")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","html-type":"submit","loading":_vm.btnSubmit.loading,"disabled":_vm.hasNewValues(_vm.form.getFieldsValue()) && !_vm.newBanner}},[_vm._v("Guardar cambios")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }